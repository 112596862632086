<template>
  <div class="be-card h-100">
    <div class="position-relative">
      <header-with-divider
        :color="smallSize ? 'blue center' : 'blue'"
        :text="$t('withdraw.withdraw_list')"
      />
    </div>
    <div
      class="align-items-center justify-content-between p-sm-4 pb-4 pt-4 pl-3 pr-3 d-flex"
    >
      <div class="card-header-right">
        <BaseInputSearch
          v-model="withdrawField.keyword"
          @cancel="cancelSearch()"
          @search="search"
        />
      </div>
      <div>
        <button
          :class="{
            'i-left': !smallSize,
            'circle-btn': smallSize,
          }"
          class="be-btn primary green lg ml-2"
          @click="$router.push({ name: 'withdraw.form' })"
        >
          <i class="icon-plus" />
          {{ !smallSize ? $t("withdraw.new_withdraw") : "" }}
        </button>
      </div>
    </div>
    <div class="divider d-none d-sm-flex" />
    <loading v-if="loading" />
    <div
      v-else-if="!loading && withdraws && withdraws.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="withdraws"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template v-slot:type="{ row }">
          <div class="d-flex">
            <i
              :class="[operationIcon(row.type), operationClass(row.type)]"
              class="op-icon"
            />
            <span>
              {{ row.status }}
            </span>
          </div>
        </template>
        <template v-slot:status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : activeClass(row.status),
              smallSize ? '' : 'status',
            ]"
          >
            {{ row.status }}
          </span>
        </template>
        <template v-slot:desc="{ row }">
          <label class="f-14" v-html="row.desc" />
          <small class="d-block text-secondary">
            <div v-if="!smallSize">
              <span class="f-w-600"> Ref :</span>
              {{ row.status }}
            </div>
          </small>
        </template>
        <template v-slot:base_amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            {{ row.base_amount + " " + row.currency }}
            <small
              v-if="!smallSize"
              :class="statusColorClass(row.status)"
              class="f-w-600"
            >
              {{ row.status }}
            </small>
          </div>
        </template>
        <template v-slot:frais="{ row }">
          <div>
            <div class="d-flex flex-column">
              <span class="f-w-600">
                {{ row.frais }}%
                <span v-if="smallSize"
                  >- {{ row.fee_amount + " " + row.currency }}</span
                >
              </span>
              <small v-if="!smallSize" class="op-8">
                {{ $t("utils.fees") }}:
                {{ row.fee_amount + " " + row.currency }}
              </small>
            </div>
          </div>
        </template>

        <template v-slot:operator="{ row }">
          <div class="d-flex flex-column">
            <img
              :src="row.information.operator && row.information.operator.logo"
              alt=""
              width="70"
            />
          </div>
        </template>
        <template v-slot:created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>

        <template v-slot:actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>
      <b-modal ref="detailsModal" no-fade size="lg" top>
        <div class="text-center">
          <b-row>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.operator") }}</div>
                <div>
                  {{ modalData && modalData.information.operator.label }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.phone_number") }}</div>
                <div>{{ modalData && modalData.information.phone }}</div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.withdraw_amount") }}</div>
                <div>
                  {{
                    modalData &&
                      `${modalData.base_amount} ${modalData.currency}`
                  }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.status") }}</div>
                <div :class="modalData && statusColorClass(modalData.status)">
                  {{ modalData && modalData.status }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.creation_date") }}</div>
                <div>
                  {{ modalData && modalData.created_at }}
                </div>
              </div>
            </b-col>
            <b-col />
          </b-row>
        </div>
        <template slot="modal-header">
          <span />
          <span class="modal-title">
            {{ $t("withdraw.withdraw_details") }} -
            {{ modalData && modalData.client_transaction_id }}
          </span>
          <span @click="hideModal">
            <i class="icon-close f-24" />
          </span>
        </template>
        <template slot="modal-footer">
          <template
            v-if="
              modalData &&
                (modalData.status === 'Echec' ||
                  modalData.status === 'En cours')
            "
          >
            <span class="f-w-600 fermer mr-4" @click="hideModal">
              {{ $t("common.close") }}
            </span>
            <button
              class="ml-4 be-btn primary lg danger"
              @click="$router.push({ name: 'assistance' })"
            >
              {{ $t("common.create_a_ticket") }}
            </button>
          </template>
          <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
            {{ $t("common.close") }}
          </button>
        </template>
      </b-modal>
      <BasePagination
        :current_page="current_page"
        :last_page="last_page"
        @page="getWithdrawList"
      />
    </div>
    <no-content
      v-else
      :text="$t('withdraw.no_withdraw_done')"
      icon="icon-withdraw"
    />
    <!-- <div
      class="d-flex d-sm-none align-items-center justify-content-center flex-fill"
    >
      <button
        class="be-btn primary green xl"
        @click="$router.push({ name: 'withdraw.form' })"
      >
        <i class="icon-plus" />Nouveau retrait
      </button>
    </div> -->
    <div v-if="isDetailsOpen" class="bg-secondary">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { emptyListModel } from "../../helpers/constants";
import _ from "lodash";
import BeTable from "../../components/BeTable";
import Loading from "../../components/common/Loading";
import NoContent from "../../components/common/NoContent";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";

export default {
  name: "WithdrawList",
  components: { BeTable, HeaderWithDivider, Loading, NoContent },
  data() {
    return {
      loading: true,
      modalData: null,
      withdrawField: {
        users_id: null,
        paginate: 10,
        search: false,
        keyword: null,
      },
      smallTableFields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "base_amount",
        },
        {
          label: this.$t("common.fees"),
          key: "frais",
        },
        {
          label: this.$t("common.operator"),
          key: "operator",
        },
        {
          label: this.$t("common.reference"),
          key: "client_transaction_id",
        },
        {
          label: "",
          key: "actions",
        },
      ],
    };
  },

  computed: {
    ...mapState("withdraw", ["metaWithdraw"]),
    ...mapGetters("withdraw", ["withdraws", "current_page", "last_page"]),
    ...mapState("auth", ["user"]),
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    isDetailsOpen() {
      return this.$route.name === "withdraw.detail";
    },
    listModel() {
      return emptyListModel.withdraw;
    },
    fields() {
      return [
        {
          label: this.$t("common.status").toUpperCase(),
          key: "status",
        },
        {
          label: this.$t("common.date").toUpperCase(),
          key: "created_at",
        },
        {
          label: this.$t("common.amount").toUpperCase(),
          key: "base_amount",
        },
        {
          label: this.$t("common.fees").toUpperCase(),
          key: "frais",
        },
        {
          label: this.$t("common.operator").toUpperCase(),
          key: "operator",
        },
        {
          label: this.$t("common.reference").toUpperCase(),
          key: "client_transaction_id",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ];
    },
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
  },

  created() {
    this.withdrawField.users_id = this.user.id;
    this.getWithdrawList(1);
  },

  methods: {
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    search: _.debounce(async function(text) {
      this.withdrawField.keyword = text;
      await this.getWithdrawList(1);
    }, 200),
    getWithdrawList(page, cancel = false) {
      this.loading = true;
      if (this.withdrawField.keyword) this.withdrawField.search = true;
      else {
        this.withdrawField.keyword = null;
        this.withdrawField.search = false;
      }
      this.$store
        .dispatch("withdraw/getWithdrawList", {
          page: page,
          cancel: cancel,
          field: this.withdrawField,
        })
        .then(() => {
          this.loading = false;
        });
    },
    cancelSearch() {
      this.withdrawField.keyword = null;
      if (this.withdrawField.search) {
        this.withdrawField.search = false;
        this.getWithdrawList(1, true);
      }
    },
    operationIcon(type) {
      switch (type) {
        case "Crédit":
          return "icon-deposit-fill";
        case "Débit":
          return "icon-withdraw-fill";
      }
    },
    operationClass(type) {
      switch (type) {
        case "Crédit":
          return "be-text-success";
        case "Débit":
          return "be-text-failure";
      }
    },
    activeClass(status) {
      switch (status) {
        case "Succès":
          return "badge-success";
        case "Echec":
          return "badge-failure";
        case "En cours":
          return "badge-info";
      }
    },
    statusColorClass(status) {
      switch (status) {
        case "Succès":
          return "be-text-success";
        case "Echec":
          return "be-text-failure";
        case "En cours":
          return "be-text-encours";
      }
    },
  },
};
</script>
<style scoped>
.phone {
  display: inline-block;
  max-width: 155px;
}

.modal-title {
  color: #0b1527;
  font-weight: 600;
}

.icon-close {
  cursor: pointer;
}

.fermer {
  cursor: pointer;
}

/deep/ .modal-backdrop {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(3px) !important;
}
</style>
